<template>
   <div>
      <div class="container">
         <div class="dim" v-if="loading">
            <div class="ring-loader">
               <ring-loader :loading="loading" color='#F96302' size="100px"></ring-loader>
            </div>
         </div>
         <div v-cloak v-if="isMounted">
            <header>
               <div class="row">
                  <div class="col-md-12 no-gutters">
                     <div class="page-header">
                        <a v-bind:href="homeDepotLink">
                           <img :src="require('./assets/img/THD-975-LOGO-0.png')" alt="" width="158" height="55" />
                        </a>
                     </div>
                  </div>
               </div>
               <!-- /.row -->
            </header>
            <!-- .row -->
            <div v-if="primaryReview && primaryReview.show">
               <div v-if="primaryReview.errors">
                  <!-- if there's an error, display the message here with the form -->
                  <div class="primary-product-errors col-md-12">
                     <div class="alert notification green-left-border col-md-12" v-if="primaryReview.ratingOnly">
                        <div class="green-checkmark">&#10003;</div>
                        <div class="notification-text">
                           Thank you for submitting a Ratings Only Review.
                        </div>
                     </div>
                     <div class="alert notification grey-left-border col-md-12" v-if="primaryReview.ratingOnly">
                        <div>
                           If you would like to add more detail to your review, please do so in the Review Summary box.
                        </div>
                     </div>
                     <div class="alert alert-danger display-errors col-md-12" role="alert"
                        v-if="primaryReview.errors.length > 0 && !primaryReview.ratingOnly">
                        <p>
                           <strong>Your review had the following error(s):</strong>
                        <ul class="errors-list">
                           <li v-for="(error, index) in primaryReview.errors" :key="index" v-text="error.error"></li>
                        </ul>
                        </p>
                     </div>
                     <div class="primary-product-form product">
                        <div class="row">
                           <div class="mi_blocks">
                              <div class="mi_block_left">
                                 <img alt="Display images to show real-time content" style="border: 0; display: block;"
                                    border="0" v-bind:src="primaryReview.image" />
                              </div>
                              <div class="mi_block_right">
                                 <img alt="Display images to show real-time content" style="border: 0; display: block;"
                                    border="0" v-bind:src="primaryReview.desc" />
                                 <div class="row col-md-9">
                                    <!-- Primary Product Rating form -->
                                    <div class="primary-rating-form">
                                       <div class="review-descript-submit primary">
                                          <div class="form-group">
                                             <h3>Product Review</h3>
                                             <div v-if="!primaryReview.review || primaryReview.show" class="row col-md-12 col-xs-12">
                                                <star-rating active-color="#F96302" :border-width="1" v-model="primaryReview.review"
                                                   :show-rating="false" :star-size="30"
                                                   @rating-selected="[eventLogger($event, primaryReview), ratingSelected(primaryReview)]"></star-rating>
                                                <div id="rating-form-primary" class="rating primary star-container col-md-4">
                                                </div>
                                             </div>
                                             <label class="review_summary" for="review_summary">
                                                Review Summary
                                             </label>
                                             <textarea class="form-control review-summary primary" name="review_summary"
                                                rows="4"
                                                placeholder="Example: This drill was easy to assemble, lightweight and quiet. I’m really pleased with the battery life and was able to use the drill for over an hour of heavy use without having to charge it..."
                                                v-model="primaryReview.reviewText"></textarea>
                                             <div v-if="shippingReview">
                                                <div class="review_summary">
                                                   <br />How was your service? (Optional)
                                                </div>
                                                <div class="mi_block_left">Delivery</div>
                                                <div class="mi_block_right">
                                                   <star-rating active-color="#F96302" :border-width="1"
                                                      v-model="primaryReview.ratingDelivery" :show-rating="false"
                                                      :star-size="30"></star-rating>
                                                </div>
                                                <div class="mi_block_left">Customer service</div>
                                                <div class="mi_block_right">
                                                   <star-rating active-color="#F96302" :border-width="1"
                                                      v-model="primaryReview.ratingService" :show-rating="false"
                                                      :star-size="30"></star-rating>
                                                </div>
                                             </div>
                                             <div class="form-group nickname no-gutters">
                                                <div class="view-nickname-group">
                                                   <span>
                                                      Posting as
                                                      <span class="thd_orange primary-nickname nickname-value">{{
                                                         primaryReview.userNickname
                                                      }}</span>
                                                      |
                                                      <a class="edit-nickname nickname primary" href="#"
                                                         @click.prevent="[eventLogger($event, primaryReview), showNicknameEdit = true]">
                                                         Edit
                                                      </a>
                                                   </span>
                                                </div>
                                                <div class="form-group edit-nickname-group primary"
                                                   v-if="showNicknameEdit">
                                                   <input class="primary-nickname-input form-control nickname"
                                                      name="nickname-input" type="text"
                                                      v-model="primaryReview.userNickname" />
                                                   <button type="button"
                                                      class="btn btn-primary btn-lg full-btn submit-nickname nickname primary"
                                                      @click.prevent="[eventLogger($event, primaryReview), showNicknameEdit = false]">
                                                      Done
                                                   </button>
                                                </div>
                                             </div>
                                             <!-- #primary-add-image-success -->
                                             <div
                                                class="primary-add-image-success col-md-12 col-sm-12 col-xs-12 no-gutters hidden">
                                                <div class="col-md-2 col-sm-3 col-xs-3 pull-left">
                                                   <img src="" />
                                                </div>
                                                <div
                                                   class="col-md-10 col-sm-8 col-xs-8 alert pull-left no-gutters visible-lg visible-md visible-sm visible-xs">
                                                   <div
                                                      class="submit-check-0 primary image-check inline col-md-12 pull-left no-gutters visible-lg visible-md visible-sm visible-xs">
                                                      <div class="check-mark">&#10003;</div>
                                                      <div
                                                         class="subproduct-image-success visible-lg visible-md visible-sm visible-xs">
                                                         Your image has been added successfully.</div>
                                                   </div>
                                                </div>
                                             </div>
                                             <!-- /#primary-add-image-success -->
                                          </div>
                                          <div class="row">
                                                <div class="input-group pull-right form-buttons">
                                                   <button type="button" data-toggle="modal"
                                                      data-target="#primary-prod-upload-img-modal"
                                                      class="btn btn-primary btn-lg sharp full-btn pull-left op-add-img-btn"
                                                      @click.prevent="[eventLogger($event, primaryReview), showModal(primaryReview)]">
                                                      <span class="glyphicon glyphicon-camera">
                                                      </span>
                                                      &nbsp;&nbsp;&nbsp;
                                                      <span class="cam-btn-txt">
                                                         add an image
                                                      </span>
                                                   </button>
                                                   <button
                                                      class="btn btn-primary sharp-solid full-btn pull-right rating-form-submit rating-submit primary">
                                                      <span class="submit-btn-txt"
                                                         @click.prevent="[eventLogger($event, primaryReview), postReview(primaryReview)]">
                                                         Submit
                                                      </span>
                                                   </button>
                                                </div>
                                             </div>
                                          </div>
                                          <div class="col-md-12">
                                                <div class="terms-guidelines">
                                                   <a class="terms" v-bind:href="termsLink" target="_blank">Terms
                                                      &amp; Conditions</a>
                                                   |
                                                   <a class="guidelines" v-bind:href="guidelinesLink"
                                                      target="_blank">Review Guidelines</a>
                                          </div>
                                       </div>
                                       <input type="hidden" name="thumbnail" id="thumbnail" />
                                       <!-- <input type="hidden" name="blackBox" id="blackBox"></input> -->
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div id="primary-add-image" class="primary-add-image">
               <div id="primary-errors" class="alert alert-danger display-errors col-md-12" role="alert"
                  v-if="errors.length > 0">
                  <p>
                     <strong>Your review had the following error(s):</strong>
                  <ul id="primary-errors-list" class="errors-list">
                     <ul class="errors-list">
                        <li v-for="error in errors" :key="error.id" v-text="error"></li>
                     </ul>
                  </ul>
                  </p>
               </div>
               <div class="primary-product-no-errors col-md-12 no-gutters" v-if="primaryReview && primaryReview.submitted">
                  <div class="col-md-10 no-gutters">
                     <p class="header-txt-0">
                        Thank you for your review
                     </p>
                     <p class="header-txt-1">
                        Note, it may take up to 48 hours for your submission to be posted on homedepot.com.
                     </p>
                     <button @click.prevent="[eventLogger($event, primaryReview), showModal(primaryReview)]" type="button"
                        data-toggle="modal" v-if="!primaryReview.imageUrl" data-target="#primary-prod-upload-img-modal"
                        class="btn btn-primary btn-lg sharp full-btn header-add-img-btn">
                        <span class="glyphicon glyphicon-camera">
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        <span class="cam-btn-txt">
                           add an image
                        </span>
                     </button>
                  </div>
               </div>
               <div class="col-md-12 rem" v-if="primaryReview && primaryReview.imageUrl">
                  <div class="width-100-per">
                     <div id="primary-add-image-success"
                        class="primary-add-image-success col-md-12 col-sm-12 col-xs-12 no-gutters">
                        <div class="col-md-2 primary-success-img">
                           <img v-bind:src="primaryReview.imageUrl" />
                        </div>
                        <div class="primary-success-message">
                           <div class="submit-check-0 primary hero inline">&#10003;&nbsp;</div>
                           <div class="hero-image-success-text">
                              Your image has been added successfully.
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- Spacer -->
               <div class="top-padd-14"></div>
               <!-- Spacer -->
            </div>
            <!-- .row -->
            <!-- GREY BORDER -->
            <div class="grey-border">&nbsp;</div>
            <!-- sub products container -->
            <div v-if="subReviews">
               <div class="row">
                  <p class="sub_heading">Please help others by reviewing your other recent purchases:</p>
               </div>
            </div>
            <div class="row" v-for="review in reviews" :key="review.id">
               <!-- if there's an error, display the message here with the form -->
               <div class="primary-product-errors col-md-12" v-if="!review.submitted">
                  <div class="alert notification green-left-border col-md-12" v-if="review.ratingOnly">
                     <div class="green-checkmark">&#10003;</div>
                     <div class="notification-text">
                        Thank you for submitting a Ratings Only Review.
                     </div>
                  </div>
                  <div class="alert notification grey-left-border col-md-12" v-if="review.ratingOnly">
                     <div>
                        If you would like to add more detail to your review, please do so in the Review Summary box.
                     </div>
                  </div>
                  <div class="alert alert-danger display-errors col-md-12" role="alert" v-if="review.errors.length > 0">
                     <p>
                        <strong>Your review had the following error(s):</strong>
                     <ul class="errors-list">
                        <li v-for="(error, index) in review.errors" :key="index" v-text="error.error"></li>
                     </ul>
                     </p>
                  </div>
                  <div class="primary-product-form product">
                     <div class="row">
                        <div class="mi_blocks">
                           <div class="mi_block_left">
                              <img alt="Display images to show real-time content" style="border: 0; display: block;"
                                 border="0" v-bind:src="review.image" />
                           </div>
                           <div class="mi_block_right">
                              <img alt="Display images to show real-time content" style="border: 0; display: block;"
                                 border="0" v-bind:src="review.desc" />
                                 <h3>Product Review</h3>
                              <div v-if="!review.review || review.show" class="row col-md-12 col-xs-12">
                                 <star-rating active-color="#F96302" :border-width="1" v-model="review.review"
                                    :show-rating="false" :star-size="30"
                                    @rating-selected="[eventLogger($event, review), ratingSelected(review)]"></star-rating>
                                 <div id="rating-form-primary" class="rating primary star-container col-md-4">
                                 </div>
                              </div>
                              <div v-if="review.show">
                                 <!-- Primary Product Rating form -->
                                 <div class="primary-rating-form">
                                    <div class="review-descript-submit primary">
                                       <div class="form-group">
                                          <label class="review_summary" for="review_summary">
                                             Review Summary
                                          </label>
                                          <textarea class="form-control review-summary primary" name="review_summary"
                                             rows="4"
                                             placeholder="Example: This drill was easy to assemble, lightweight and quiet. I’m really pleased with the battery life and was able to use the drill for over an hour of heavy use without having to charge it..."
                                             v-model="review.reviewText"></textarea>
                                          <div v-if="shippingReview">
                                             <div class="review_summary">
                                                <br />How was your service? (Optional)
                                             </div>
                                             <div class="mi_block_left">Delivery</div>
                                             <div class="mi_block_right">
                                                <star-rating active-color="#F96302" :border-width="1"
                                                   v-model="review.ratingDelivery" :show-rating="false"
                                                   :star-size="30"></star-rating>
                                             </div>
                                             <div class="mi_block_left">Customer service</div>
                                             <div class="mi_block_right">
                                                <star-rating active-color="#F96302" :border-width="1"
                                                   v-model="review.ratingService" :show-rating="false"
                                                   :star-size="30"></star-rating>
                                             </div>
                                          </div>
                                          <div class="form-group nickname no-gutters">
                                             <div class="view-nickname-group">
                                                <span>
                                                   Posting as
                                                   <span class="thd_orange primary-nickname nickname-value">{{
                                                      review.userNickname
                                                   }}</span>
                                                   |
                                                   <a class="edit-nickname nickname primary" href="#"
                                                      @click.prevent="[eventLogger($event, review), showNicknameEdit = true]">
                                                      Edit
                                                   </a>
                                                </span>
                                             </div>
                                             <div class="form-group edit-nickname-group primary" v-if="showNicknameEdit">
                                                <input class="primary-nickname-input form-control nickname"
                                                   name="nickname-input" type="text" v-model="review.userNickname" />
                                                <button type="button"
                                                   class="btn btn-primary btn-lg full-btn submit-nickname nickname primary"
                                                   @click.prevent="[eventLogger($event, review), showNicknameEdit = false]">
                                                   Done
                                                </button>
                                             </div>
                                          </div>
                                          <!-- #primary-add-image-success -->
                                          <div class="primary-add-image-success col-md-12 col-sm-12 col-xs-12 no-gutters"
                                             v-if="review.imageUrl">
                                             <div class="col-md-2 col-sm-3 col-xs-3 pull-left">
                                                <img src="" />
                                             </div>
                                             <div
                                                class="col-md-10 col-sm-8 col-xs-8 alert pull-left no-gutters visible-lg visible-md visible-sm visible-xs">
                                                <div
                                                   class="submit-check-0 primary image-check inline col-md-12 pull-left no-gutters visible-lg visible-md visible-sm visible-xs">
                                                   <div class="check-mark">&#10003;</div>
                                                   <div
                                                      class="subproduct-image-success visible-lg visible-md visible-sm visible-xs">
                                                      Your image has been added successfully.</div>
                                                </div>
                                             </div>
                                          </div>
                                          <!-- /#primary-add-image-success -->
                                       </div>
                                       <div class="row">
                                          <div class="col-md-12">
                                             <div class="input-group pull-right form-buttons">
                                                <button type="button" data-toggle="modal"
                                                   data-target="#primary-prod-upload-img-modal"
                                                   class="btn btn-primary btn-lg sharp full-btn pull-left op-add-img-btn"
                                                   @click.prevent="[eventLogger($event, review), showModal(review)]">
                                                   <span class="glyphicon glyphicon-camera">
                                                   </span>
                                                   &nbsp;&nbsp;&nbsp;
                                                   <span class="cam-btn-txt">
                                                      add an image
                                                   </span>
                                                </button>
                                                <button
                                                   class="btn btn-primary sharp-solid full-btn pull-right rating-form-submit rating-submit primary">
                                                   <span class="submit-btn-txt"
                                                      @click.prevent="[eventLogger($event, review), postReview(review)]">
                                                      Submit
                                                   </span>
                                                </button>
                                             </div>
                                          </div>
                                       </div>
                                       <div class="terms-guidelines">
                                          <a class="terms" v-bind:href="termsLink" target="_blank">Terms
                                             &amp; Conditions</a>
                                          |
                                          <a class="guidelines" v-bind:href="guidelinesLink" target="_blank">Review
                                             Guidelines</a>
                                       </div>
                                    </div>
                                    <input type="hidden" name="thumbnail" id="thumbnail" />
                                    <!-- <input type="hidden" name="blackBox" id="blackBox"></input> -->
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="primary-product-no-errors col-md-12 no-gutters" v-if="review.submitted">
                  <div class="col-md-10 no-gutters">
                     <div class="submit-check-0 submit-checkmark primary-rating-success">&#10003;</div>
                     <p class="header-txt-1">
                        Note, it may take up to 48 hours for your submission to be posted on homedepot.com.
                     </p>
                     <button @click.prevent="[eventLogger($event, review), showModal(review)]" type="button"
                        data-toggle="modal" v-if="!review.imageUrl" data-target="#primary-prod-upload-img-modal"
                        class="btn btn-primary btn-lg sharp full-btn header-add-img-btn">
                        <span class="glyphicon glyphicon-camera">
                        </span>
                        &nbsp;&nbsp;&nbsp;
                        <span class="cam-btn-txt">
                           add an image
                        </span>
                     </button>
                  </div>
               </div>
               <div class="col-md-12 rem" v-if="review.imageUrl">
                  <div class="width-100-per">
                     <div id="primary-add-image-success"
                        class="primary-add-image-success col-md-12 col-sm-12 col-xs-12 no-gutters">
                        <div class="col-md-2 primary-success-img">
                           <img v-bind:src="review.imageUrl" />
                        </div>
                        <div class="primary-success-message">
                           <div class="submit-check-0 primary hero inline">&#10003;&nbsp;</div>
                           <div class="hero-image-success-text">
                              Your image has been added successfully.
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <!-- Spacer -->
               <div class="top-padd-14"></div>
            </div>

            <!-- /. sub products container -->
            <!-- Spacer -->
            <div class="top-padd-14">
            </div>
            <!-- Spacer -->
            <footer class="container">
               <div class="row">
                  <p class="small col-md-12">&copy; 2000-2023 Home Depot Product Authority, LLC. All Rights Reserved. Use
                     of this site is subject to certain <a v-bind:href="termsLink" target="_blank" alias="">Terms Of
                        Use.</a></p>
                  <p class="small col-md-12"><a v-bind:href="securityLink" target="_blank" alias="">Privacy &amp; Security
                        Statement</a></p>
               </div>
               <!-- /.row -->
            </footer>
         </div>
      </div>
      <!-- /. main container -->
      <!--[if lte IE 8]>
   <script src="https://ajax.googleapis.com/ajax/libs/jquery/1.12.4/jquery.min.js"></script>
   <![endif]-->
      <!-- Modal -->
      <Transition v-if="showImageUpload">
         <div class="modal fade" id="primary-prod-upload-img-modal" tabindex="-1" role="dialog"
            aria-labelledby="upload-img-label" aria-hidden="true" v-bind:class="{ 'modal-open': showImageUpload }">
            <div class="modal-dialog" role="document">
               <div class="modal-content">
                  <div class="modal-header">
                     <h5 class="modal-title" id="upload-img-label">Add an image</h5>
                     <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        @click.prevent="[eventLogger($event, uploadImageReview), showImageUpload = false]">
                        <span aria-hidden="true">&times;</span>
                     </button>
                  </div>
                  <div class="modal-body">
                     <div id="primary-image-add-form">
                        <p>Upload your photo here. The photo must be at least 400px tall or wide and under 10MB. The
                           following
                           formats are accepted.</p>
                        <ul class="image-upload-modal">
                           <li>BMP</li>
                           <li>PNG</li>
                           <li>GIF</li>
                           <li>JPG</li>
                        </ul>
                        <div class="">
                           <input type="file" size="60" name="photo" accept="image/*" ref="reviewImage" />
                        </div>
                     </div>
                  </div>
                  <div class="modal-footer">
                     <button type="button" class="btn sharp" data-dismiss="modal"
                        @click.prevent="[eventLogger($event, uploadImageReview), showImageUpload = false]">Close</button>
                     <button id="primary-image-submit" type="button" class="btn sharp-done image-submit"
                        @click.prevent="[eventLogger($event, uploadImageReview), uploadFile()]">Add Image</button>
                  </div>
               </div>
            </div>
         </div>
      </Transition>
   </div>
</template>

<script>
import axios from "axios";
import StarRating from 'vue-star-rating';
import RingLoader from 'vue-spinner/src/RingLoader.vue';
import { v4 as uuidv4 } from 'uuid';

const PROD_API = "https://services.messagegears.io";
const STAGING_API = "http://3.228.122.224:8081";
const LOCAL_API = "http://127.0.0.1:8084";
const API_KEY = "eF7l7&$*A87&6KX97mlr";
const CAMPAIGN_ID_FORMAT = "msg_email-_-{0}-_-{1}_{2}_{3}-_-{4}-_-{5}-_-{6}"
const CLICK_TRACKING = "/thd/click/";
const DUPLICATE_SUBMISSION_ERROR = "Duplicate submission";
const EXISTS_URI = "/thd/review/exists/";
const LOCAL_HOST = "127.0.0.1";
const MAX_USERNAME_LENGTH = 25;
const MIN_REVIEW_LENGTH = 20;
const MIN_USER_LENGTH = 1;
const PROD_HOST = "reviews.page.mg.homedepot.com";
const LEGACY_HOST = "reviews.messagegears.io";
const REVIEW_LENGTH_ERROR = "The review summary must be at least 20 characters.";
const LINK_FORMAT = "https://mi.homedepot.com/p/cp/273ef9f3727d5986/c?mi_u={0}&mi_ign={1}&mi_store_id={2}&mi_sku={3}&url=https%3A%2F%2Fmi.homedepot.com%2Fp%2Frp%2F95aa3250d2400246%2Furl"
const IMAGE_FORMAT = "https://mi.homedepot.com/p/rp/4d4a7224e132a80f.png?mi_u={0}&mi_ign={1}&mi_store_id={2}&mi_sku={3}";
const DESC_FORMAT = "https://mi.homedepot.com/p/rp/3ebfd5ee9d4d8117.png?mi_u={0}&mi_ign={1}&mi_store_id={2}&mi_sku={3}";
const HOME_DEPOT_LINK_FORMAT = "http://homedepot.com/?cm_mmc={0}";
const TERMS_LINK_FORMAT = "https://5.homedepot.review/terms?cm_mmc={0}";
const GUIDELINES_LINK_FORMAT = "https://5.homedepot.review/terms#reviewGuidelines?cm_mmc={0}";
const SECURITY_LINK_FORMAT = "https://www.homedepot.com/privacy/privacy-and-security-statement?cm_mmc={0}";
const EVENT_LOGGING_ENDPOINT = "https://37ulml5v5v3dapmsmg7f6pdg6m0qzxpw.lambda-url.us-east-1.on.aws/";

export default {
   name: 'App',
   components: {
      StarRating,
      RingLoader
   },
   data() {
      return {
         api: STAGING_API,
         browserType: null,
         data: null,
         isMounted: false,
         jobId: "",
         loading: true,
         errors: [],
         file: '',
         fileUploaded: false,
         uploadImageReview: null,
         noErrors: true,
         primaryReview: null,
         reviews: [],
         showNicknameEdit: false,
         showImageUpload: false,
         showStars: true,
         subReviews: false,
         homeDepotLink: "",
         termsLink: "",
         guidelinesLink: "",
         securityLink: "",
         token: null,
         blackBox: null,
         timeoutId: null,
         shippingReview: false
      }
   },
   mounted: async function () {
      this.iovation();
      this.setApi();
      this.token = this.$route.query.token;
      if (this.$route.query.ship != null && this.$route.query.ship === "1") {
         this.shippingReview = Boolean(this.$route.query.ship).valueOf();
      }
      this.clickTracking();
      const cmMmc = this.$route.query.cm_mmc;
      this.homeDepotLink = HOME_DEPOT_LINK_FORMAT.format(cmMmc);
      this.termsLink = TERMS_LINK_FORMAT.format(cmMmc);
      this.guidelinesLink = GUIDELINES_LINK_FORMAT.format(cmMmc);
      this.securityLink = SECURITY_LINK_FORMAT.format(cmMmc);
      if (/mobile/i.test(window.navigator.userAgent)) {
         this.browserType = "Mobile";
      } else {
         this.browserType = "Desktop";
      }
      if (this.$route.query.data != null && this.$route.query.data != "") {
         if (this.$route.query.job != null && this.$route.query.job != "") {
            this.jobId = this.$route.query.job;
         }
         this.data = JSON.parse(window.atob(decodeURI(this.$route.query.data)));
         if ('products' in this.data && this.data.products.length > 0) {
            let show = true;
            for (let i = 0; i < this.data.products.length; i++) {
               if ('sku' in this.data.products[i] && this.data.products[i].sku !== "" && this.data.products[i].sku != null) {
                  if (!await this.checkExists(this.data.products[i].id)) {
                     const review = {
                        id: this.data.products[i].id,
                        imageUrl: "",
                        productId: this.data.products[i].sku,
                        review: 0,
                        ratingDelivery: null,
                        ratingService: null,
                        reviewText: "",
                        show: false,
                        store: this.data.products[i].store,
                        title: this.data.products[i].title,
                        userId: this.data.id,
                        userNickname: this.$route.query.userNickname,
                        errors: [],
                        email: this.data.email,
                        link: LINK_FORMAT.format(this.data.products[i].id, this.data.miIgn, this.data.products[i].store, this.data.products[i].sku),
                        image: IMAGE_FORMAT.format(this.data.products[i].id, this.data.miIgn, this.data.products[i].store, this.data.products[i].sku),
                        desc: DESC_FORMAT.format(this.data.products[i].id, this.data.miIgn, this.data.products[i].store, this.data.products[i].sku),
                        submitted: false,
                        campaignId: CAMPAIGN_ID_FORMAT.format(this.data.products[i].segment, this.data.sendDate, this.data.campaignName, this.jobId, this.browserType, "LP", this.data.products[i].position),
                        ratingOnly: false
                     }
                     if (show === true) {
                        review.show = true;
                     }
                     if (this.data.products[i].position == 1) {
                        review.review = Number(this.$route.query.review);
                        review.reviewText = this.$route.query.reviewText;
                        if (this.shippingReview) {
                           review.ratingDelivery = Number(this.$route.query.delivery);
                           review.ratingService = Number(this.$route.query.service);
                        }
                     }
                     this.reviews.push(review);
                     show = false;
                  }
               }
            }
         }

         if (this.reviews.length === 0) {
            this.errors.push(DUPLICATE_SUBMISSION_ERROR);
         }

         this.primaryReview = this.reviews.shift();

         if (this.primaryReview) {
            if (this.primaryReview.review > 0) {
               this.primaryReview.errors = this.validate(this.primaryReview);
               this.primaryReview.campaignId = CAMPAIGN_ID_FORMAT.format(this.data.products[0].segment, this.data.sendDate, this.data.campaignName, this.jobId, this.browserType, "EM", this.data.products[0].position);
               this.showStars = false;
               if (this.primaryReview.errors.length === 0 && this.errors.length === 0) {
                  this.primaryReview.submitted = true;
                  this.primaryReview.show = false;
               } else {
                  this.primaryReview.ratingOnly = true;
               }
               await this.post(this.primaryReview);
            }
         }
         if (this.reviews.length > 0) {
            this.subReviews = true;
         }
         //this.checkErrors();
      }
      this.isMounted = true;
      this.loading = false;
   },
   methods: {
      iovation() {
         window.IGLOO = window.IGLOO || {
            "bbout_element_id": "ioBlackBox",
            "enable_rip": true,
            "enable_flash": false,
            "install_flash": false,
            "loader": {
               "version": "general5",
               "fp_static": false
            }
         };
         require('./assets/js/loader.js');
         this.timeoutId = setInterval(this.useBlackboxString, 500);
      },
      useBlackboxString() {
         if (typeof window.IGLOO.getBlackbox !== 'function') {
            return;
         }
         const bbData = window.IGLOO.getBlackbox();
         if (bbData.finished) {
            clearTimeout(this.timeoutId);
            this.blackBox = bbData.blackbox;
         }
      },
      async eventLogger(e, input) {
         let id = "star-rating";
         let text = null;
         if (typeof e === 'object') {
            id = e.target.className;
            text = e.target.innerText;
         }
         const payload = {
            type: "pr",
            activity_id: uuidv4(),
            timestamp: new Date().toISOString(),
            landingpagePayload: {
               urlParams: this.$route.query,
               inputData: input,
               elementId: id,
               elementText: text
            }
         };
         return new Promise((resolve, reject) => {
            axios.post(EVENT_LOGGING_ENDPOINT, payload, {
               headers: {
                  "Content-Type": "application/json"
               }
            }).then((response) => {
               if (response.status) {
                  console.log(response.status);
                  resolve(true);
               }
            }).catch((error) => {
               console.log(error);
               reject(false);
            })
         });
      },
      async clickTracking() {
         if (this.token) {
            const payload = {
               token: this.token,
               userAgent: window.navigator.userAgent,
               url: window.location.href,
               destination: window.location.href
            }
            return new Promise((resolve, reject) => {
               axios.post(this.api + CLICK_TRACKING, payload, {
                  headers: {
                     "Authorization": API_KEY
                  }
               }).then((response) => {
                  if (response.status && response.status == 204) {
                     resolve(true);
                  } else {
                     reject(false);
                  }
               }).catch((error) => {
                  console.log(error);
                  reject(false);
               });
            });
         }
      },
      async checkExists(id) {
         return new Promise((resolve, reject) => {
            axios.get(this.api + EXISTS_URI + id, {
               headers: {
                  "Authorization": API_KEY
               }
            }).then(response => {
               if (response.data != null) {
                  resolve(response.data);
               } else {
                  reject("No response");
               }
            }).catch((error) => {
               console.log(error.response.data);
               reject(error.response.data);
            });
         });
      },
      validate(review) {
         let validationErrors = [];
         if (!review.reviewText || review.reviewText.length < MIN_REVIEW_LENGTH) {
            const error = {
               type: "REVIEWTEXT",
               error: REVIEW_LENGTH_ERROR
            }
            validationErrors.push(error);
         }
         if (!review.review) {
            const reviewError = {
               type: "NOREVIEW",
               error: "Rating is required"
            }
            validationErrors.push(reviewError);
         }
         return validationErrors;
      },
      checkErrors() {
         this.noErrors = true;
         if (this.errors != null && this.errors.length > 0) {
            this.noErrors = false;
            return;
         }
         this.reviews.every(review => {
            if (typeof review.errors != "undefined" && review.errors != null && review.errors.length > 0) {
               this.noErrors = false;
               return false;
            }
         })
      },
      async patch(review) {
         const reviewCopy = this.cleanseReview(review);
         return new Promise((resolve, reject) => {
            axios.patch(this.api + "/thd/review/" + review.id, reviewCopy, {
               headers: {
                  "Authorization": API_KEY
               }
            }).then((response) => {
               if (response.status && response.status == 202) {
                  resolve(true);
               } else {
                  reject(false);
               }
            }).catch((error) => {
               console.log(error);
               reject(false);
            });
         });
      },
      async postReview(review) {
         this.loading = true;
         review.ratingOnly = false;
         review.errors = this.validate(review);
         if (review.errors.length > 0) {
            this.loading = false;
            return;
         }
         if (!await this.checkExists(review.id)) {
            if (await this.post(review)) {
               this.noErrors = true;
               review.show = false;
               review.submitted = true;
            }
         } else {
            if (review.campaignId.includes("-_-EM-_-")) {
               review.campaignId = review.campaignId.replace("-_-EM-_-", "-_-EMLP-_-");
            }
            if (await this.patch(review)) {
               this.noErrors = true;
               review.show = false;
               review.submitted = true;
            }
         }
         this.loading = false;
      },
      cleanseReview(review) {
         let reviewCopy = Object.assign({}, review);
         reviewCopy.deviceFingerprint = this.blackBox;
         if (reviewCopy.userNickname.length < MIN_USER_LENGTH) {
            reviewCopy.userNickname = "HomeDepotCustomer";
         }
         if (reviewCopy.userNickname.length > MAX_USERNAME_LENGTH) {
            reviewCopy.userNickname = reviewCopy.userNickname.slice(0, MAX_USERNAME_LENGTH);
         }
         reviewCopy.userNickname = reviewCopy.userNickname.replace(/[^\w\s]/gi, '');
         if (reviewCopy.reviewText !== null && reviewCopy.reviewText.length < MIN_REVIEW_LENGTH) {
            reviewCopy.reviewText = null;
            review.ratingOnly = true;
         }
         return reviewCopy;
      },
      async post(review) {
         const reviewCopy = this.cleanseReview(review);
         return new Promise((resolve, reject) => {
            axios.post(this.api + "/thd/review", reviewCopy, {
               headers: {
                  "Authorization": API_KEY
               }
            }).then((response) => {
               if (response.status && response.status == 201) {
                  resolve(true);
               } else {
                  reject(false);
               }
            }).catch((error) => {
               console.log(error);
               reject(false);
            });
         });
      },
      ratingSelected(review) {
         review.show = true;
         review.ratingOnly = true;
         this.post(review);
      },
      setApi() {
         const hostname = location.hostname;
         if (hostname == PROD_HOST || hostname == LEGACY_HOST) {
            this.api = PROD_API;
         } else if (hostname === LOCAL_HOST) {
            this.api = LOCAL_API;
         }
      },
      showModal(review) {
         this.showImageUpload = true;
         this.uploadImageReview = review;
      },
      async uploadFile() {
         if (this.uploadImageReview) {
            this.uploadImageReview.errors = [];
            this.loading = true;
            if (!await this.checkExists(this.uploadImageReview.id)) {
               let review = structuredClone(this.uploadImageReview);
               review.reviewText = "";
               review.userNickname = "";
               review.review = 5;
               await this.post(review);
               this.uploadImageReview.show = true;
               this.uploadImageReview.submitted = false;
            }
            let formData = new FormData();
            this.file = this.$refs.reviewImage.files;
            formData.append("file", this.file[0]);
            await axios.post(this.api + "/thd/review/" + this.uploadImageReview.id + "/image", formData, {
               headers: {
                  "Authorization": API_KEY,
                  "Content-Type": "multipart/form-data"
               }
            }).then((response) => {
               this.uploadImageReview.imageUrl = response.headers.get("Location");
               this.showImageUpload = false;
               this.fileUploaded = true;
            }).catch((error) => {
               console.log(error);
            });
            this.loading = false;
         }
      }
   },
   addFile(review) {
      review.fileUpload = this.$refs.reviewImage.files[0];
   }
}

if (!String.prototype.format) {
   String.prototype.format = function (...args) {
      return this.replace(/(\{\d+\})/g, function (a) {
         return args[+(a.substr(1, a.length - 2)) || 0];
      });
   };
}
</script>
